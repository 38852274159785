@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");


.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: white;
}

.footer {
  position:fixed;
  left:0;
  bottom:0;
  right:0;
}

.body {
  height: 100%;
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.h-half {
  height: 50%;
}

.h-80 {
  height: 80%;
}

.menu-card {
  @apply flex flex-col justify-center items-center bg-black h-screen py-40;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}

.logo-size-sm {
  width: 138px;
  height: 42px;
}

.logo-size-m {
  width: 276px;
  height: 84px;
}

.logo-size-l {
  width: 552px;
  height: 168px;
}

.logo-size-xl {
  width: 1104px;
  height: 336px;
}

.INKz-gif-s {
  height: 48px;
  width: 48px;
}
.m-neg {
  margin-top: -30px;
}

.INKz-tiny {
  height: 32px;
  width: 32px;
}
.octo-logo-m {
  height: 246px;
  width: 137px;
}
.octo-logo-s {
  height: 61px;
  width: 34px;
}

.bg-dysto-blue {
  background-color: #12173d;
}
.bg-octohedz {
  background-color: #4d0408;
}
.bg-octohedz-l {
  background-color: #380404;
}
.bg-octohedz-d {
  background-color: #1f0002;
}
.bg-octo-btn {
  background-color: #e49e4f;
}
.bg-octo-btn:hover {
  background-color: #c09463;
}
.bg-octo-pink {
  background-color: #d80e58;
}
.bg-octo-pink:hover {
  background-color: #b30c49;
}
.t-octo-pink {
  color: #d80e58;
}
.t-octohedz {
  color: #c6e893;
}



.max-w-vx-avatar {
  max-width: 250px;
}
.h-px-400 {
  height: 400px;
}




/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #06141f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-green-500 {
  background-color: #8CFF9B;
  color: #12173D;
}

.text-green-500 {
  color: #8CFF9B;;
}

.navbar-color {
  color: #78FAE6;
}

.logohome {
  margin-top: 30px;
  margin-bottom: 30px;
}

.bg-green-600 {
  background-color: #8CFF9B;
  color: #12173D;
  margin-bottom: 22px;
  margin-top: 22px;
  border-radius: 5px;
}

.bg-green-500 {
  background-color: #8CFF9B;
  color: #12173D;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.bg-green-500:hover {
  background-color: #42BC7F;
}

.bg-green-600:hover {
  background-color: #42BC7F;
}

.bg-blue-500 {
  background-color: #78FAE6;
  color: #12173D;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bg-blue-500:hover {
  background-color: #27D3CB;
}

.bg-blue2-500 {
  background-color: #27D3CB;
  color: #12173D;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bg-blue2-600 {
  background-color: #27D3CB;
  color: #12173D;
  margin-bottom: 22px;
  margin-top: 22px;
  border-radius: 5px;
}

.bg-blue2-500:hover {
  background-color: #00AAA5;
}

.bg-blue2-600:hover {
  background-color: #00AAA5;
}

.bg-pink-600 {
  background-color: #E54286;
  color: #12173D;
  border-radius: 5px;
}

.bg-pink-500 {
  background-color: #E54286;
  color: #12173D;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bg-gen {
  background-color: #dd9090;
  color: #12173D;
}
.bg-rel {
  background-color: #ddd290;
  color: #12173D;
}

.bg-pink-500:hover {
  background-color: #B22E69;
}

.bg-pink-600:hover {
  background-color: #B22E69;
}

.bg-grey-500 {
  background-color: #C1D9F2;
  color: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bg-grey-500:hover {
  background-color: #C1D9F2;
}

.bg-grey-700 {
  background-color: #C1D9F2;
  color: #FFFFFF;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.bg-grey-700:hover {
  background-color: #C1D9F2;
}

.btn-margin {
  margin-top: 30px;
}

.claim-margin {
  margin: 20px;
}
.w-100 {
  width: 100%;
}
.dystos {
  margin-top: 10px;
  padding: 3px;
  background-color: #FFE091;
  color: #12173D;
  font-size: 14px;
  border-radius: 5px;
}

.octoz {
  margin-top: -30px;
  margin-bottom: 4px;
  padding: 3px;
  background-color: #91ff9a;
  color: #12173D;
  font-size: 14px;
  border-radius: 5px;
}

.bg-black {
  background-color: #000000;
}

.bg-black:hover {
  background-color: #252525;
}
.bg-octo-w {
  background-color: #c70303;
}

.bg-octo-w:hover {
  background-color: #c3c3c3;
}

.connect-w {
  margin-top: 30px;
}

.mint-sub2 {
  margin-right: 10px;
}

.text-home {
  color: #78FAE6;
}

.connect-w3 {
  color: #78FAE6;
  font-style: normal;
}

.mint-tittle {
  font-size: 24px;
}

.mint-tittle2 {
  font-size: 24px;
  margin-bottom: 30px;
}

.mint-tittle3 {
  font-size: 24px;
  margin-bottom: 34px;
}

.text-claim {
  font-size: 18px;
  margin-top: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 14px;
  padding-right: 14px;
}

.claim-input {
  width: 50%;
}

.claim-status {
  color: #78FAE6;
  margin-top: 10px;
}

.claim-sub {
  margin-bottom: 10px;
  margin-top: 10px;
}